import React, { useState, useEffect } from "react";
import emptyBag from "../assets/empty-shopping-bag.png";
import { useHistory } from "react-router";
import axios from "axios";
import { useSnackbar } from "notistack";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import BackButton from "../components/BackButton";
import {
  FaMapMarkerAlt,
  FaMobileAlt,
  FaCalendarAlt,
  FaMoneyBillAlt,
} from "react-icons/fa";
import { motion } from "framer-motion";

const MyOrders = () => {
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const auth = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    getCart();
  }, []);

  const getCart = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/order/getinitialdata`,
        {
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setCart(res.data.data.orders);
          enqueueSnackbar("Orders Fetched", { variant: "success" });
        } else {
          enqueueSnackbar("No Orders available", { variant: "error" });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
        setLoading(false);
      });
  };

const getStatusColor = (status) => {
  switch (status) {
    case "ordered":
      return "blue-600"; // This will apply the background color `bg-blue-600`
    case "shipped":
      return "green-600"; // This will apply the background color `bg-green-600`
    case "delivered":
      return "purple-600"; // This will apply the background color `bg-purple-600`
    case "cancelled":
      return "red-600"; // This will apply the background color `bg-red-600`
    default:
      return "gray-600"; // This will apply the background color `bg-gray-600`
  }
};

  const getProductNames = (order) => {
    return order?.order_items
      .map((item) => item.product_details.title)
      .join(", ");
  };

  return (
    <PageWrapper>
      <BackButton />
      <div className="py-4">
        {cart && <h1 className="text-2xl font-bold mb-6">My Orders</h1>}
        {cart.length > 0 ? (
          <div className="flex flex-col gap-6">
            {cart?.map((order) => (
              <motion.div
                key={order.order_id}
                className="bg-white rounded-md shadow-md cursor-pointer hover:shadow-lg transition-shadow duration-300 ease-in-out"
                onClick={() => history.push(`/orders/${order.order_id}`)}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                {/* <div className="relative">
                  <div
                    className={`absolute top-2 right-2 px-3 py-1 m-2 bg-${getStatusColor(
                      order.status
                    )} text-white uppercase font-semibold rounded-full`}
                  >
                    {order.status}
                  </div>
                </div> */}
                <div className="p-6">
                  <h2 className="text-xl font-semibold mb-4">
                    {order.full_name}
                  </h2>
                  <div className="">
                    <div className="w-full sm:w-1/2">
                      <p className="flex items-center gap-2 mb-2 truncate">
                        <FaMapMarkerAlt className="text-gray-500" />{" "}
                        {`${order.street1}, ${order.street2}, ${order.city}, ${order.pincode}`}
                      </p>
                      <p className="flex items-center gap-2 mb-2 truncate">
                        <FaMobileAlt className="text-gray-500" />{" "}
                        {order.mobile_no}
                      </p>
                    </div>
                    <div className="w-full sm:w-1/2">
                      <p className="flex items-center gap-2 mb-2 truncate">
                        <FaCalendarAlt className="text-gray-500" /> Order Date:{" "}
                        {new Date(order.order_date).toLocaleDateString("en-GB")}
                      </p>
                      <p className="flex items-center gap-2 mb-2 truncate">
                        <FaMoneyBillAlt className="text-gray-500" /> Total
                        Price: ₹ {order.billing_amount}
                      </p>
                    </div>
                  </div>
                  <p className="text-gray-600 mt-4">
                    <strong>Products:</strong> {getProductNames(order)}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        ) : (
          <div className="h-[60vh] w-full flex flex-col items-center justify-center gap-6">
            <img
              src={emptyBag}
              alt="empty bag"
              className="h-36 -rotate-12 mt-5 drop-shadow-lg"
            />
            <div className="text-center">
              <h2 className="text-2xl font-bold">Hey, it feels so light!</h2>
              <p className="text-sm text-gray-400 mt-2">
                There's nothing in your bag. Let's add some items.
              </p>
            </div>

            <button
              className="btn-rounded-secondary text-sm mt-5"
              onClick={() => history.push("/products")}
            >
              Explore
            </button>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default MyOrders;
