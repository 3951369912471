import React, { useState, useEffect } from "react";
import emptyBag from "../assets/empty-shopping-bag.png";
import CartItemCard from "../components/cart/CartItemCard";
import CartTotalCard from "../components/cart/CartTotalCard";
import { useHistory } from "react-router";
import axios from "axios";
import { useSnackbar } from "notistack";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import BackButton from "../components/BackButton";

const CartPage = () => {
  const [cart, setCart] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const auth = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    getCart();
  }, []);

  const updateCart = (cart)=>{
    setCart(cart);
    enqueueSnackbar("Cart Updated", { variant: "success" });

  }

  const getCart = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/order/getinitialdata`,
        {
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setCart(res.data.data.cart);
          enqueueSnackbar("Cart Fetched", { variant: "success" });
        } else {
          enqueueSnackbar("Please Login", { variant: "error" });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  return (
    <PageWrapper>
      <BackButton />
      <div className="py-2 ">
        {cart && <h1 className="text-2xl font-bold p-3 ">Cart</h1>}
        {cart?.order_items?.length > 0 ? (
          <div className="md:grid md:grid-cols-3 gap-5">
            <main className="md:col-span-2">
              {cart?.order_items?.map((product) => (
                <CartItemCard product={product} updateCart={updateCart} />
              ))}
            </main>
            <CartTotalCard cart={cart?.order_items} />
          </div>
        ) : (
          <div className="h-[60vh] w-full flex flex-col items-center justify-center  gap-3 ">
            <img
              src={emptyBag}
              alt="empty bag"
              className="h-36 -rotate-12 mt-5 drop-shadow-lg"
            />
            <div className="text-center">
              <h2 className="text-2xl font-bold">Hey, it feels so light!</h2>
              <p className="text-sm text-gray-400">
                There's nothing in your bag. Let's add some items.
              </p>
            </div>

            <button
              className="btn-rounded-secondary text-sm mt-5"
              onClick={() => history.push("/products")}
            >
              Explore
            </button>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default CartPage;
