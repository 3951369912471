import {
  Redirect,
  Route,
  BrowserRouter as Router,
  Switch,
} from "react-router-dom";
import {
  IonApp,
  IonRouterOutlet,
  setupIonicReact,
  IonSplitPane,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import LandingPage from "./pages/LandingPage/LandingPage";
import { SnackbarProvider } from "notistack";

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
// import "@ionic/react/css/normalize.css";
// import "@ionic/react/css/structure.css";
// import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
// import "@ionic/react/css/padding.css";
// import "@ionic/react/css/float-elements.css";
// import "@ionic/react/css/text-alignment.css";
// import "@ionic/react/css/text-transformation.css";
// import "@ionic/react/css/flex-utils.css";
// import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";
import { Preferences } from "@capacitor/preferences";

import "./App.css";
import Menu from "./components/Menu/Menu";
import { makeStyles } from "@material-ui/styles";
import ProductListing from "./pages/ProductListing";
import SharedLayout from "./routes/SharedLayout";
import ProductDetails from "./pages/ProductDetails";
import Signup from "./pages/Signup";
import CartPage from "./pages/CartPage";
import CheckoutPage from "./pages/CheckoutPage";
import SearchPage from "./pages/SearchPage";
import MyOrders from "./pages/MyOrders";
import Order from "./pages/Order";
import Account from "./pages/Account";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CancellationPolicy from "./pages/CancellationPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import ContactUs from "./pages/ContactUs";
import TermsOfUse from "./pages/TermsOfUse";
import AboutUs from "./pages/AboutUs";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentFailure from "./pages/PaymentFailure";
import Homepage from "./pages/HomePage";
import MyAddress from "./pages/MyAddress";
import Login from "./pages/Login";
import AddAddress from "./pages/AddAddress";

setupIonicReact();

// const islogin = localStorage.getItem("loggedIn");
// const islogin = Preferences.get({ key: 'loggedIn' });

// console.log(islogin);
const useStyles = makeStyles(() => ({
  containerAnchorOriginBottomLeft: {
    bottom: 0,
    zIndex: `200000 !important`,
  },
}));

const App = () => {
  const classes = useStyles();

  return (
    <Router basename="/">
      <Switch>
        <SnackbarProvider
          preventDuplicate
          autoHideDuration={2000}
          classes={{ containerRoot: "z-alert" }}
        >
          <Route exact path="/">
            <Homepage />
          </Route>
          <Route exact path="/products">
            <ProductListing />
          </Route>
          <Route path="/products/:id">
            <ProductDetails />
          </Route>
          <Route exact path="/signup">
            <Signup />
          </Route>
          <Route exact path="/login">
            <Login />
          </Route>
          <Route exact path="/cart">
            <CartPage />
          </Route>
          <Route exact path="/checkout">
            <CheckoutPage />
          </Route>
          <Route exact path="/search">
            <SearchPage />
          </Route>
          <Route exact path="/orders">
            <MyOrders />
          </Route>
          <Route path="/orders/:id">
            <Order />
          </Route>
          <Route path="/account">
            <Account />
          </Route>
          <Route path="/addaddress">
            <AddAddress />
          </Route>
          <Route path="/about-us">
            <AboutUs />
          </Route>
          <Route path="/privacy-policy">
            <PrivacyPolicy />
          </Route>
          <Route path="/cancellation-policy">
            <CancellationPolicy />
          </Route>
          <Route path="/refund-policy">
            <ReturnPolicy />
          </Route>
          <Route path="/tnc">
            <TermsOfUse />
          </Route>
          <Route path="/my-address">
            <MyAddress />
          </Route>
          <Route path="/contact-us">
            <ContactUs />
          </Route>
          <Route path="/payment-success">
            <PaymentSuccess />
          </Route>
          <Route path="/payment-failure">
            <PaymentFailure />
          </Route>
        </SnackbarProvider>
      </Switch>
    </Router>

    // <IonApp style={{ innerHeight: "auto" }}>
    // </IonApp>

    //      {/* <IonSplitPane contentId="main"> */}
    //      {/* <Menu /> */}
    //      <IonRouterOutlet id="main">
    //        <SnackbarProvider
    //          preventDuplicate
    //          autoHideDuration={2000}
    //          classes={{ containerRoot: "z-alert" }}
    //        >
    //          <Route exact path="/">
    //            <LandingPage />
    //          </Route>

    //          {/* <Route exact path="/products">
    //            <ProductListing />
    //          </Route>
    //          <Route path="/products/:id">
    //            <ProductDetails />
    //          </Route>
    //          <Route path="/signup">
    //            <Signup />
    //          </Route> */}
    //        </SnackbarProvider>
    //      </IonRouterOutlet>
    //      {/* </IonSplitPane> */}
  );
};

export default App;
