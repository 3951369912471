import React from "react";
import BackButton from "../components/BackButton";

const AboutUs = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center px-4">
      <div className="max-w-4xl bg-white rounded-lg shadow-lg p-8 m-5">
      <BackButton />
        <h1 className="text-3xl font-bold mb-6">About Us</h1>
        <p className="mb-4">
          Pancham Records is a online one stop shop for all the types of
          Physical Audio Media , be it Analog or Digital . Based in Pune / Jalna
          in Maharashtra - India, Pancham Records offers customers Gramophone
          Records and all other media with true images of the product,and proper
          grading so the buyer has an exact idea of what he is buying . We Offer
          media in a wide range of Genre , procured from famous and
          knowledgeable sellers across India
        </p>
        <p className="mb-4">
          The idea of an online shop incurred to us when we felt that most of
          the genuine sellers across india weren’t capable to sell online , and
          that we could buy from those sellers and sell online. we found many
          potential buyers from India and abroad were still in search of a
          genuine platform to fullfill their requirements . Being an aRDent
          Pancham aka Rahul Dev Burman Fan , Pancham Records was born and we
          have been selling Gramophone records and other media online via social
          media platforms since 2023 .
        </p>

        <div className="mb-8">
          <p className="mb-4">
            Whether you’re a seasoned collector or just starting your vinyl
            journey, Pancham Records is the perfect place to fill your wishlist
            .
          </p>
          <p className="mb-4">
            We are sure that once you buy from us , you will never need to go
            anywhere else . We wish to add Audio Equipments and Accessories in
            future .
          </p>
          <p className="mb-4">Sheetal Praveen Jaiswal </p>
          <p className="mb-4">Founder</p>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
