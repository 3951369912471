// shippingData.js

const domesticShippingRates = {
  upTo2: 200,
  threeTo5: 400,
  sixTo8: 600,
  nineTo10: 800,
};

const internationalShippingRates = {
  "Vinyl Record": {
    upTo2: 3500,
    threeTo5: 4700,
    sixTo8: 6000,
    nineTo10: 7200,
    elevenTo13: 8500,
    fourteenTo15: 10000,
  },
  Cassette: {
    upTo2: 3000,
    threeTo5: 4000,
    sixTo8: 5000,
    nineTo10: 6000,
    elevenTo13: 7000,
    fourteenTo15: 8000,
  },
  CD: {
    upTo2: 2500,
    threeTo5: 3200,
    sixTo8: 4000,
    nineTo10: 4800,
    elevenTo13: 5600,
    fourteenTo15: 6400,
  },
  Liquid: {
    upTo2: 5000,
    threeTo5: 6500,
    sixTo8: 8000,
    nineTo10: 9500,
    elevenTo13: 11000,
    fourteenTo15: 12500,
  },
};

const freeShippingThreshold = 5000;

export {
  domesticShippingRates,
  internationalShippingRates,
  freeShippingThreshold,
};
