import { CiSearch } from "react-icons/ci";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";

import { useLocation, useNavigate } from "react-router-dom";
import spinningLoaders from "../../assets/loaderBlack.svg";

const Search = () => {

  const location = useLocation();
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [showList, setShowList] = useState(true);
  const [searching, setSearching] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (location?.pathname !== "/products") {
      setSearch("");
    }
  }, [location]);

  const handleFormClick = () => {
 
      // If search is not empty and showList is true, navigate to search page
      history.push("/search");
    
  };

  return (
    <>
      <div onClick={handleFormClick}>
        <form
          className={`flex items-center bg-black/[0.075] px-3 ${
            search && showList ? "rounded-t-md" : "rounded-full"
          } text-sm transition`}
        >
          <input
            className="w-full py-2 px-3 bg-transparent focus:outline-none"
            type="search"
            value={search}
            placeholder="Search Products"
          />
          <CiSearch />
        </form>
      </div>
    </>
  );
};

export default Search;
