import axios from "axios";

const getUserData = () => {
  try {
    return JSON.parse(localStorage.getItem("userData")) || {};
  } catch (error) {
    console.error("Error parsing userData from localStorage:", error);
    return {};
  }
};

const createAxiosInstance = () => {
  return axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
  });
};

const handleApiError = (error, functionName) => {
  console.error(`Error in ${functionName}:`, error);
  throw error;
};

const generateTxnId = async (orderId, amount) => {
  try {
    const axiosInstance = createAxiosInstance();
    const userData = getUserData();

    const response = await axiosInstance.post("/product/pay/create_payment", {
      amount: amount,
      orderid: orderId,
      firstname: userData.name || "",
      email: userData.email || "user@pancham.com",
      phone: userData.phone_no || "",
    });

    return {
      txnid: response.data.txn_id,
      hash: response.data.hash,
    };
  } catch (error) {
    handleApiError(error, "generateTxnId");
  }
};

const initiatePayment = async (orderId, amount) => {
  try {
    const { txnid, hash } = await generateTxnId(orderId, amount);
    const userData = getUserData();

    const paymentData = {
      key: process.env.REACT_APP_PAYU_KEY || "4AXlXi",
      txnid: txnid,
      amount: amount,
      orderid: orderId,
      firstname: userData.name || "",
      email: userData.email || "user@pancham.com",
      phone: userData.phone_no || "",
      surl: `${process.env.REACT_APP_BASEURL}/product/pay/payment-response`,
      furl: `${process.env.REACT_APP_BASEURL}/product/pay/payment-response`,
      productinfo: orderId,
      hash: hash,
    };

    return new Promise((resolve) => {
      const form = document.createElement("form");
      form.method = "post";
      form.action =
        process.env.REACT_APP_PAYU_URL || "https://secure.payu.in/_payment";
      form.style.display = "none";

      Object.entries(paymentData).forEach(([name, value]) => {
        const input = document.createElement("input");
        input.type = "hidden";
        input.name = name;
        input.value = value;
        form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();

      resolve();
    });
  } catch (error) {
    handleApiError(error, "initiatePayment");
  }
};

export default initiatePayment;
