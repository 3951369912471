import React from "react";

const ReturnPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center px-4">
      <div className="max-w-4xl bg-white rounded-lg shadow-lg p-8 m-5">
        <h1 className="text-3xl font-bold mb-6">Refund Policy</h1>
        <p className="mb-4">
          In the event You receive a damaged / defective product or a product
          that does not comply with the specifications as per your original
          order, You are required to get in touch with the customer service team
          through any of the below mentioned channels:
        </p>
        <p className="mb-4">
          Contact Us : +919422577724, +919422020363, Mail –
          panchamrecords@gmail.com
        </p>
        <p className="mb-4">
          Upon receiving Your complaint, Pancham Records shall verify the
          authenticity and the nature of the complaint and if we are convinced
          that the complaint is genuine, Pancham Records will inform the
          relevant Vendor of such complaint and request for a replacement.
          However, in the event of frivolous and baseless complaints regarding
          the quality and content of the products, Pancham Records reserves the
          right to take necessary legal actions against You and You will be
          solely liable for all costs incurred by Pancham Records in this
          regard. You expressly acknowledge that the Vendor selling the
          defective product/ service will be solely responsible to You for any
          claims that You may have in relation to such defective product/
          service and Pancham Records shall not in any manner be held liable for
          the same.
        </p>
        <p className="mb-4">
          Before accepting shipment of any product, kindly ensure that the
          product's packaging is not damaged or tampered. If You observe that
          the package is damaged or tampered, request You to refuse to accept
          delivery and inform Pancham Records at the earliest. The return
          process of the product may be restricted by Pancham Records depending
          on the nature and category of the product.
        </p>
        <p className="mb-4">
          In order to return any products sold through the Website, You are
          required to comply with the below mentioned conditions, viz:
        </p>
        <ol className="list-decimal list-inside mb-4">
          <li>
            Please notify Pancham Records of receipt of a damaged/ defective
            product/service within 48-hours of delivery to You. If You are
            unable to do so within 48-hours, Pancham Records shall not be held
            liable for the failure to replace the order;
          </li>
          <li>Products should be unused;</li>
          <li>
            Pancham Records will arrange pick-up of the damaged/defective
            product through its own logistics partner. In the event Pancham
            Records is unable to do so, Pancham Records will notify You
            regarding the same and You will be required to dispatch the product
            using a reputed courier in Your respective area within one (1) day
            from receipt of such notice. Courier freight charges will be
            reimbursed in such form as is determined by the logistics team upon
            prior consultation with Pancham Records;
          </li>
          <li>
            Products should be returned in their original packaging along with
            the original price tags, labels, barcodes, user manual, warranty
            card and invoices etc.;
          </li>
          <li>
            It is advised that the return packets should be strongly and
            adequately packaged so that there is no further damage of products
            during transit;
          </li>
          <li>
            The returned products are subject to verification and checks by
            Pancham Records in order to determine the legitimacy of the
            complaint/ return.
          </li>
        </ol>
        <p className="mb-4">
          In the event the return of a product is duly accepted by Pancham
          Records, the value of such product, as originally paid by You during
          acceptance of delivery of product or otherwise, will be refunded to
          You. Refund will be processed based on the mode of payment and Pancham
          Records or the approved payment gateway will credit Your refunds
          directly into Your debit/credit card or online accounts or provide You
          with a cheque in this regard. Refunds will be subject to the
          following:
        </p>
        <ol className="list-decimal list-inside mb-4">
          <li>
            Orders paid online will be refunded within 7-21 working days through
            the online account or via cheque, depending on the then current
            circumstances as determined by Pancham Records;
          </li>
          <li>
            For Cash on Delivery payments, You will be provided with a refund
            cheque.
          </li>
          <li>
            If the product can be repaired by the service centre, Pancham
            Records would get the same repaired and send it back to You.
          </li>
          <li>
            A promo code, once used shall not be refunded in case of
            cancellation of order either by customer or Pancham Records.
          </li>
        </ol>
      </div>
    </div>
  );
};

export default ReturnPolicy;
