import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router";
import { useSnackbar } from "notistack";
import {
  postAddProductToCartService,
  postRemoveProductFromCart,
  updateProductQtyInCart,
} from "../../api/apiServices";

const CartItemCard = ({ product, isSearch, setSearch, updateCart }) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const updateHandler = async (type) => {
    try {
      let updatedQuantity;

      if (type === "increment") {
        updatedQuantity = product.quantity + 1;
      } else {
        updatedQuantity = product.quantity - 1;
      }

  
        const data = {
          product_id: parseInt(product.product_id),
          qty: updatedQuantity,
        };

        const response = await postAddProductToCartService(data);

        if (response.data.success === true) {
          updateCart(response.data.data.cart);
        }
        else {
            enqueueSnackbar(
              "Error Ocuured" + " " + response.data.message,
              {
                variant: "error",
              }
            );
        }
      }
     catch (error) {
       enqueueSnackbar("Error Ocuured" + " " + error, {
         variant: "error",
       });
      console.error("Error:", error);
    }
  };

  const removeProduct = async () => {
    try {
      const data = {
        product_id: parseInt(product.product_id),
        qty: 0,
      };

      const response = await postRemoveProductFromCart(data);

      if (response.status === 200) {
        updateCart(response.data.data.cart);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div
      className={`m-auto flex flex-col gap-2  p-4 rounded-sm shadow-sm bg-white/[0.6] mb-2 max-w-xl ${
        isSearch ? "cursor-pointer hover:bg-black/5" : ""
      }`}
      onClick={() => {
        if (isSearch) {
          setSearch("");
          history.push(`products/${product.product_id}`);
        }
      }}
    >
      <div className="flex  items-center flex-wrap gap-2 w-full">
        <div className="flex flex-wrap xs:flex-nowrap justify-center xs:justify-start flex-1 items-center gap-5">
          <div
            className={` bg-black/[0.075] ${
              isSearch ? "h-14 w-14 " : "h-28 w-28"
            } rounded-md flex items-center cursor-pointer hover:bg-black/5`}
          >
            <img
              src={`https://pancham-bucket.s3.amazonaws.com/${
                JSON.parse(product.product_details.product_images)[0]
              }`}
              alt=""
              className="object-fit w-full"
              onClick={() => {
                history.push(`products/${product.product_id}`);
              }}
            />
          </div>
          <div className="flex flex-col gap-3">
            <h2 className="text-xl py-3 font-semibold">
              {product?.product_details.title}
            </h2>

            {!isSearch && (
              <div className="flex flex-col gap-3">
                <div className="flex gap-2 items-center">
                  <span className="text-gray-700">Quantity: </span>
                  <button
                    className="bg-[--primary-text-color] p-1 text-gray-100 rounded-md  text-xs disabled:cursor-not-allowed"
                    // disabled={disableCart}
                    onClick={() => updateHandler("decrement")}
                  >
                    -{/* <AiOutlineMinus /> */}
                  </button>
                  <span className="h-full w-10 bg-black/[0.075]  rounded-sm flex items-center justify-center">
                    {product.quantity}
                  </span>
                  <button
                    className="bg-[--primary-text-color] p-1 text-gray-100 rounded-md text-xs disabled:cursor-not-allowed"
                    // disabled={disableCart}
                    onClick={() => updateHandler("increment")}
                  >
                    +{/* <AiOutlinePlus /> */}
                  </button>
                </div>
                {/* <div className="flex gap-1 sm:gap-3  ">
                  <button
                    className="btn-rounded-secondary  text-xs sm:text-sm mt-2 max-w-xs disabled:cursor-not-allowed"
                    // disabled={disableCart}
                    onClick={() => removeProduct()}
                  >
                    Remove from Cart
                  </button>
                </div> */}
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col items-end">
          <span>₹{product.product_details.selling_price}</span>
          <span className="text-xs line-through text-gray-600">
            ₹ {product.product_details.mrp}
          </span>
        </div>
      </div>
    </div>
  );
};

export default CartItemCard;
