import React from "react";

const CancellationPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center px-4">
      <div className="max-w-4xl bg-white rounded-lg shadow-lg p-8 m-5">


        

        <div>
          <h2 className="text-2xl font-bold mb-4">Cancellation Policy</h2>
          <p className="mb-4">
            <strong>Cancellation by Pancham Records:</strong> There may be
            certain orders that Pancham Records is unable to accept and must
            cancel. Pancham Records reserves the right, at Pancham Records's
            sole discretion, to refuse or cancel any order for any reason
            whatsoever. Some situations that may result in Your order being
            cancelled include, without limitation, non-availability of the
            product or quantities ordered by You, non- availability of the
            service, inaccuracies or errors in pricing information, or problems
            identified by Pancham Records's credit and fraud avoidance
            department. Pancham Records may also require additional
            verifications or information before accepting any order. Pancham
            Records will contact You if all or any portion of Your order is
            cancelled or if additional information is required to accept Your
            order. If Your order is cancelled after Your credit card has been
            charged, the said amount will be reversed back in Your credit card
            account. A promo code, once used shall not be refunded in case of
            cancellation of order either by Customer or Pancham Records.
          </p>
          <p className="mb-4">
            <strong>Cancellation by the User:</strong> In case of requests for
            order cancellations, Pancham Records reserves the right to accept or
            reject requests for order cancellations for any reason whatsoever.
            As part of usual business practice, if Pancham Records receives a
            cancellation notice and the order has not been processed/ approved
            by Pancham Records, Pancham Records shall cancel the order and
            refund the entire amount to You within a reasonable period of time.
            Pancham Records will not be able to cancel orders that have already
            been processed. Pancham Records has the full right to decide whether
            an order has been processed or not. You agree not to dispute the
            decision made by Pancham Records and accept Pancham Records's
            decision regarding the cancellation.
          </p>
          <p className="mb-4">
            Pancham Records reserves the right to cancel any orders that
            classify as 'Bulk Order' as determined by Pancham Records as per
            certain criteria. Any Pancham Records Reward Point or Promo Code
            used for placing the 'Bulk Order' will not be refunded as per this
            cancellation policy. An order can be classified as 'Bulk Order' if
            it meets with the below mentioned criteria, which may not be
            exhaustive, viz:
          </p>
          <ol className="list-decimal list-inside mb-4">
            <li>
              Products ordered are not for self-consumption but for commercial
              resale
            </li>
            <li>Multiple orders placed for same product at the same address</li>
            <li>Bulk quantity of the same product ordered</li>
            <li>Invalid address given in order details</li>
            <li>Any malpractice used to place the order</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default CancellationPolicy;
