import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center px-4">
      <div className="max-w-4xl bg-white rounded-lg shadow-lg p-8 m-5">
        <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
        <p className="mb-4">
          This Privacy policy is subject to the terms of the Site Policy (User
          agreement) of Pancham Records. This policy is effective from the date
          and time a user registers with Pancham Records by filling up the
          Registration form and accepting the terms and conditions laid out in
          the Site Policy.
        </p>
        <p className="mb-4">
          In order to provide a personalised browsing experience, Pancham
          Records may collect personal information from you. Additionally our
          website may require you to complete a registration form or seek some
          information from you. When you let us have your preferences, we will
          be able to deliver or allow you to access the most relevant
          information that meets your end.
        </p>
        <p className="mb-4">
          "By using the website and/or registering yourself with us you
          authorise us to contact you via email or phone call or sms and offer
          you our services, imparting product knowledge, offer promotional
          offers running on website & offers offered by third parties, for which
          reasons, personally identifiable information may be collected. And
          irrespective of the fact if also you have registered yourself under
          DND or DNC or NCPR service, you still authorise us to give you a call
          from Pancham Records / www.panchamrecords.com for the above mentioned
          purposes till 365 days of your registration with us."
        </p>
        <p className="mb-4">
          To extend this personalized experience Pancham Records may track the
          IP address of a user's computer and save certain information on your
          system in the form of cookies. A user has the option of accepting or
          declining the cookies of this website by changing the settings of your
          browser.
        </p>
        <p className="mb-4">
          The personal information provided by the users to Pancham Records will
          not be provided to third parties without previous consent of the user
          concerned. Information of a general nature may however be revealed to
          external parties
        </p>
        <p className="mb-4">
          Every effort will be made to keep the information provided by users in
          a safe manner, the information will be displayed on the website will
          be done so only after obtaining consent from the users. Any user
          browsing the site generally is not required to disclose his identity
          or provide any information about him/her, it is only at the time of
          registration you will be required to furnish the details in the
          registration form.
        </p>
        <p className="mb-4">
          A full user always has the option of not providing the information
          which is not mandatory. You are solely responsible for maintaining
          confidentiality of the User password and user identification and all
          activities and transmission performed by the User through his user
          identification and shall be solely responsible for carrying out any
          online or off-line transaction involving credit cards / debit cards or
          such other forms of instruments or documents for making such
          transactions and Pancham Records assumes no responsibility or
          liability for their improper use of information relating to such usage
          of credit cards / debit cards used by the subscriber online /
          off-line.
        </p>
        <p className="mb-4">
          You agree that Pancham Records may use personal information about you
          to improve its marketing and promotional efforts, to analyze site
          usage, improve the Site's content and product offerings, and customise
          the Site's content, layout, and services. These uses improve the Site
          and better tailor it to meet your needs, so as to provide you with a
          smooth, efficient, safe and customised experience while using the
          Site.
        </p>
        <p className="mb-4">
          You agree that Pancham Records may use your personal information to
          contact you and deliver information to you that, in some cases, are
          targeted to your interests, such as targeted banner advertisements,
          administrative notices, product offerings, and communications relevant
          to your use of the Site. By accepting the User Agreement and Privacy
          Policy, you expressly agree to receive this information. If you do not
          wish to receive these communications, we encourage you to opt out of
          the receipt of certain communications in your profile. You may make
          changes to your profile at any time.
        </p>
        <p className="mb-4">
          It is the belief of Pancham Records that privacy of a person can be
          best guaranteed by working in conjunction with the Law enforcement
          authorities.
        </p>
        <p className="mb-4">
          www.panchamrecords.com website is fully comply with all Indian Laws
          applicable. Pancham Records has always cooperated with all law
          enforcement inquires. Pancham Records may disclose all or part of your
          personal details in response to a request from the law enforcement
          authorities or in a case of bonafide requirement to prevent an
          imminent breach of the law.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
