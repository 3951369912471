import { useEffect, useState } from "react";
import  SummaryCard  from "../components/checkout/SummaryCard";
import Address from "../components/address/Address";
import { useLocation } from "react-router";
import { getinitialdata } from "../api/apiServices";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import BackButton from "../components/BackButton";

const CheckoutPage = () => {

  const [cartData, setCartData] = useState(null);
  const location = useLocation();
  const [selectedAddressId, setSelectedAddressId] = useState(null);


    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await getinitialdata();
          setCartData(response.data.data.cart);
        } catch (error) {
          console.error("Error fetching initial data:", error);
        }
      };

      fetchData();
    }, []);

     const handleAddressSelect = (id) => {
       setSelectedAddressId(id);
     };

  return (
    <PageWrapper>
        <BackButton />
      <div className="md:min-h-[80vh] flex justify-center items-center">
        <main className="grid md:grid-cols-2 gap-10 w-full">
          <section className="p-7 rounded-md shadow-sm bg-white/[0.7] flex flex-col gap-6 w-full h-min">
            <Address onAddressSelect={handleAddressSelect} showRadio={true} />

          </section>
          {cartData && (
            <SummaryCard
              cartData={cartData}
              selectedAddressId={selectedAddressId}
            />
          )}
        </main>
      </div>
    </PageWrapper>
  );
};

export default CheckoutPage;
