import React, { useState, useEffect } from "react";
import emptyBag from "../assets/empty-shopping-bag.png";
import { useHistory, useLocation, useParams } from "react-router-dom";
import axios from "axios";
import { useSnackbar } from "notistack";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import {
  FaShippingFast,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaCalendarAlt,
  FaMoneyBillAlt,
} from "react-icons/fa";
import { motion } from "framer-motion";

const Order = () => {
  const [order, setOrder] = useState([]);
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const auth = localStorage.getItem("authToken");
  const { id } = useParams();

  useEffect(() => {
    getOrder();
  }, []);

  const getOrder = async () => {
    setLoading(true);
    await axios
      .post(
        `${process.env.REACT_APP_BASEURL}/order/getorder`,
        {
          order_id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      )
      .then((res) => {
        if (res.data.success) {
          setOrder(res.data.data.orders);
          enqueueSnackbar("Order Fetched", { variant: "success" });
        } else {
          enqueueSnackbar("Failed", { variant: "error" });
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Error occurred. Please try again.", {
          variant: "error",
        });
        setLoading(false);
      });
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "ordered":
        return "text-blue-600";
      case "shipped":
        return "text-green-600";
      case "delivered":
        return "text-purple-600";
      case "cancelled":
        return "text-red-600";
      default:
        return "text-gray-600";
    }
  };

  const getProductNames = (order) => {
    return order?.order_items
      .map((item) => item.product_details.title)
      .join(", ");
  };



  return (
    <PageWrapper>
      <div className="py-2">
        {order ? (
          <motion.div
            className="m-auto flex flex-col gap-2 p-4 rounded-sm shadow-sm bg-white relative"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            {/* <div
              className={`absolute top-0 right-0 p-2 ${getStatusColor(
                order.status
              )} uppercase font-bold rounded-tr-sm rounded-bl-sm`}
            >
              {order.status}
            </div> */}
            <div className="flex flex-col gap-3">
              <h2 className="text-2xl font-bold">{order.full_name}</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <div>
                  <p className="flex items-center gap-2 mb-2">
                    <FaMapMarkerAlt className="text-gray-500" /> {order.street1}
                    , {order.street2}, {order.city}, {order.pincode}
                  </p>
                  <p className="flex items-center gap-2 mb-2">
                    <FaMobileAlt className="text-gray-500" /> {order.mobile_no}
                  </p>
                </div>
                <div>
                  <p className="flex items-center gap-2 mb-2">
                    <FaCalendarAlt className="text-gray-500" /> Order Date:{" "}
                    {new Date(order.order_date).toLocaleDateString("en-GB")}
                  </p>
                  <p className="flex items-center gap-2 mb-2">
                    <FaMoneyBillAlt className="text-gray-500" /> Total Price: ₹
                    {order.billing_amount}
                  </p>
                </div>
              </div>

              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2 flex items-center gap-2">
                  <FaShippingFast className="text-gray-500" /> Order Items
                </h3>
                {order &&
                  order?.order_items?.map((item, index) => (
                    <motion.div
                      key={item.order_item_id}
                      className="bg-gray-100 p-4 rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out mb-2"
                      initial={{ opacity: 0, x: -20 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.5, delay: 0.1 * index }}
                    >
                      <h4 className="font-bold">
                        {item.product_details.title}
                      </h4>
                      <p className="text-sm text-gray-600 mb-2">
                        {item.product_details.description}
                      </p>
                      <p className="text-sm">
                        <strong>Product Type:</strong>{" "}
                        {item.product_details.product_type}
                      </p>
                      <p className="text-sm">
                        <strong>Condition:</strong>{" "}
                        {item.product_details.condition_type}
                      </p>
                      <p className="text-sm">
                        <strong>Quantity:</strong> {item.quantity}
                      </p>
                      <p className="text-sm">
                        <strong>Total Price:</strong> ₹{item.total_price}
                      </p>
                    </motion.div>
                  ))}
              </div>

              <div className="mt-4">
                <h3 className="text-lg font-semibold mb-2 flex items-center gap-2">
                  <FaShippingFast className="text-gray-500" /> Shipment Details
                </h3>
                {order?.shipments?.map((shipment) => (
                  <motion.div
                    className="bg-gray-100 p-4 rounded-md hover:bg-gray-200 transition-colors duration-300 ease-in-out mb-2"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.2 }}
                  >
                    <p className="text-gray-800 mb-1">
                      <strong>Shipment Status:</strong>{" "}
                      {shipment.shipment_details.status
                        ? shipment.shipment_details.status
                        : "Pending"}
                    </p>
                    <p className="text-gray-800 mb-1">
                      <strong>Tracking ID:</strong>{" "}
                      {shipment.shipment_details.tracking_id}
                    </p>
                    <p className="text-gray-800 mb-2">
                      <strong>Products:</strong>{" "}
                      {shipment?.shipment_details?.product_ids
                      ?
                      // JSON.parse(shipment.shipment_details.product_ids).map(
                        (shipment.shipment_details.product_ids).map(
                        (itemId, idx) => (
                          <p>
                            Product Name {idx + 1}:{" "}
                            {order.order_items.find(
                              (item) => item.product_id === parseInt(itemId)
                            )?.product_details.title || "Product Not Found"}
                          </p>
                        )
                      ): "No products found"
                    }
                    </p>
                    {shipment.shipment_details.link && (
                      <button
  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded transition-colors duration-300 ease-in-out"
  onClick={() => {
    let url = shipment?.shipment_details?.link;
    if (url && !/^https?:\/\//i.test(url)) {
      url = `https://${url}`; 
    }
    window.open(url, "_blank", "noopener,noreferrer");
  }}
>
  Track Shipment
</button>
                    )}
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>
        ) : (
          <div className="h-[60vh] w-full flex flex-col items-center justify-center  gap-3 ">
            <img
              src={emptyBag}
              alt="empty bag"
              className="h-36 -rotate-12 mt-5 drop-shadow-lg"
            />
            <div className="text-center">
              <h2 className="text-2xl font-bold">Hey, it feels so light!</h2>
              <p className="text-sm text-gray-400">
                There's nothing in your bag. Let's add some items.
              </p>
            </div>

            <button
              className="btn-rounded-secondary text-sm mt-5"
              onClick={() => history.push("/products")}
            >
              Explore
            </button>
          </div>
        )}
      </div>
    </PageWrapper>
  );
};

export default Order;
