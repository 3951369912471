import React from "react";
import { Link } from "react-router-dom";
import MainLogo from "../../imgs/main-logo.png";

const Logo = () => {
  return (
    <Link to="/" className="block">
      <img
        src={MainLogo}
        alt="logo"
        className="w-20 sm:w-24 md:w-40 lg:w-40 max-w-full h-auto"
      />
    </Link>
  );
};

export default Logo;
