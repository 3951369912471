import React from "react";

const PriceCard = ({ product }) => {
  return (
    <div key={product.id} className=" flex  justify-between  ">
      <p className=" text-gray-600 flex-1">
        {product.product_details.title} ({product.quantity}) {product.product_details.product_type}
      </p>

      <p className="text-lg ml-2">₹ {product.total_price}</p>
    </div>
  );
};

export default PriceCard;
