import React from "react";
import { FaPhoneAlt, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import Navbar from "../components/navbar/Navbar";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import BackButton from "../components/BackButton";

const ContactUs = () => {
  return (
    <PageWrapper>
       <BackButton />
      <div className="flex justify-center ">
        <div className="max-w-4xl p-8">
          <h1 className="text-3xl font-bold mb-6 text-center">Contact Us</h1>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="bg-gray-100 rounded-lg p-6 flex flex-col items-center">
              <FaPhoneAlt className="text-4xl mb-4 text-gray-600" />
              <h2 className="text-xl font-bold mb-2">Phone</h2>
              <p className="mb-2 text-gray-600">+919422577724</p>
              <p className="text-gray-600">+919422020363</p>
            </div>
            <div className="bg-gray-100 rounded-lg p-6 flex flex-col items-center">
              <FaEnvelope className="text-4xl mb-4 text-gray-600" />
              <h2 className="text-xl font-bold mb-2">Email</h2>
              <p className="text-gray-600">sales@panchamrecords.com</p>
              <p className="text-gray-600">panchamrecords@gmail.com</p>
            </div>
            <div className="bg-gray-100 rounded-lg p-6 flex flex-col items-center">
              <FaMapMarkerAlt className="text-4xl mb-4 text-gray-600" />
              <h2 className="text-xl font-bold mb-2">Pancham Records</h2>
              <p className="text-gray-600">302 , Kailash Vidya Residency ,</p>
              <p className="text-gray-600">
                Mahalaxmi Nagar , Mantha Chaufully ,
              </p>
              <p className="text-gray-600">Jalna – 431203 ( Maharashtra )</p>
            </div>
            <div className="bg-gray-100 rounded-lg p-6 flex flex-col items-center">
              <FaMapMarkerAlt className="text-4xl mb-4 text-gray-600" />
              <h2 className="text-xl font-bold mb-2">Dispatch Office</h2>
              <p className="text-gray-600">Pancham Records</p>
              <p className="text-gray-600">Khardekar Complex,</p>
              <p className="text-gray-600">Opp Mission Hospital,</p>
              <p className="text-gray-600">Jalna – 431203 ( Maharashtra )</p>
            </div>
            <div className="bg-gray-100 rounded-lg p-6 flex flex-col items-center">
              <FaEnvelope className="text-4xl mb-4 text-gray-600" />
              <h2 className="text-xl font-bold mb-2">
                Grievances / Complaints
              </h2>
              <p className="text-gray-600">praveen@panchamrecords.com</p>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};

export default ContactUs;
