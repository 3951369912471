import React, { useState } from "react";
import { addAddress } from "../../api/apiServices";

const AddressForm = ({
  setShowAddressForm,
  editAddress,
  setEditAddress,
  onSuccess,
  hideCancel = false,
}) => {
  const [newAddress, setNewAddress] = useState(
    editAddress
      ? editAddress
      : {
          full_name: "",
          mobile_no: JSON.parse(localStorage.getItem("userData"))?.phone_no,
          street1: "",
          street2: "",
          city: "",
          pincode: "",
          type: "Domestic",
        }
  );

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      await addAddress({ ...newAddress });
      setNewAddress({
        full_name: "",
        mobile_no: "",
        street1: "",
        street2: "",
        city: "",
        pincode: "",
        type: "Domestic",
      });

      onSuccess();
    } catch (error) {
      console.error("Error adding address:", error);
    }
  };

  return (
    <form
      action=""
      className="flex flex-col gap-3 p-5 bg-gray-50 shadow-sm"
      onSubmit={submitHandler}
    >
      <div className="flex flex-col gap-2">
        <div className="flex gap-4 items-center">
          <label className="flex items-center gap-2">
            <input
              type="radio"
              value="Domestic"
              checked={newAddress.type === "Domestic"}
              onChange={(e) =>
                setNewAddress({ ...newAddress, type: e.target.value })
              }
            />
            Domestic
          </label>
          <label className="flex items-center gap-2">
            <input
              type="radio"
              value="International"
              checked={newAddress.type === "International"}
              onChange={(e) =>
                setNewAddress({ ...newAddress, type: e.target.value })
              }
            />
            International
          </label>
        </div>
      </div>
      <div className="flex gap-2 flex-wrap">
        <label className="flex flex-1 flex-col">
          Full Name
          <input
            required
            type="text"
            className="border rounded-md p-1.5 shadow-sm"
            onChange={(e) =>
              setNewAddress({ ...newAddress, full_name: e.target.value })
            }
            value={newAddress.full_name}
          />
        </label>
        <label className="flex flex-1 flex-col">
          Mobile No.
          <input
          disabled
            required
            value={
              JSON.parse(localStorage.getItem("userData"))?.phone_no
              
            }
            type="number"
            className="border rounded-md p-1.5 shadow-sm"
            onChange={(e) =>
              setNewAddress({ ...newAddress, mobile_no: e.target.value })
            }
       
          />
        </label>
      </div>
      <label className="flex flex-col">
        Flat, House no., Building
        <input
          required
          type="text"
          className="border rounded-md p-1.5 shadow-sm"
          onChange={(e) =>
            setNewAddress({ ...newAddress, street1: e.target.value })
          }
          value={newAddress.street1}
        />
      </label>
      <label className="flex flex-col">
        Area, Colony, Street
        <input
          required
          type="text"
          className="border rounded-md p-1.5 shadow-sm"
          onChange={(e) =>
            setNewAddress({ ...newAddress, street2: e.target.value })
          }
          value={newAddress.street2}
        />
      </label>
      <div className="flex gap-2 flex-wrap">
        <label className="flex flex-1 flex-col">
          Town/City
          <input
            required
            type="text"
            className="border rounded-md p-1.5 shadow-sm"
            onChange={(e) =>
              setNewAddress({ ...newAddress, city: e.target.value })
            }
            value={newAddress.city}
          />
        </label>
        <label className="flex flex-1 flex-col">
          Pin Code
          <input
            required
            type="number"
            className="border rounded-md p-1.5 shadow-sm"
            onChange={(e) =>
              setNewAddress({ ...newAddress, pincode: e.target.value })
            }
            value={newAddress.pincode}
          />
        </label>
      </div>

      <div className="flex gap-3 mt-3 flex-wrap">
        {!hideCancel && (
          <button
            type="button"
            className="btn-rounded-secondary rounded-full flex items-center gap-2 text-sm"
            onClick={() => {
              setShowAddressForm(false);
              setNewAddress({
                full_name: "",
                mobile_no: "",
                street1: "",
                street2: "",
                city: "",
                pincode: "",
              });
              if (editAddress) {
                setEditAddress(null);
              }
            }}
          >
            Cancel
          </button>
        )}
        <button
          type="submit"
          className="btn-rounded-primary rounded-full flex items-center gap-2 text-sm"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default AddressForm;
