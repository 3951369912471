import Button from '@mui/material/Button';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useHistory } from "react-router";
import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  palette: {
    ochre: {
      main: '#a16207',
      light: '#a16205',
      dark: '#854d0e',
      contrastText: '#854d0e',
    },
  },
});
function BackButton() {
  const history = useHistory();
  return (
    <ThemeProvider theme={theme}>

    <Button variant="contained" color="ochre" onClick={() => history.goBack()} style={{marginBottom: "2rem", color: "white"}} startIcon={<ArrowBackIosNewIcon />}>
              Back
        </Button>
    </ThemeProvider>
  );
}

export default BackButton;
