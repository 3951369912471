import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import AddressForm from "../components/address/AddressForm";

const AddAddress = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const handleAddressSuccess = () => {
    enqueueSnackbar("Address added successfully", { variant: "success" });
    history.replace("/");
  };

  return (
    <PageWrapper hideNav>
      <div className="">
        <h1 className="text-2xl font-bold p-3">Add Address</h1>
        <AddressForm onSuccess={handleAddressSuccess} hideCancel={true} />
      </div>
    </PageWrapper>
  );
};

export default AddAddress;
