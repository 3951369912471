import { useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import SingleProduct from "./SingleProduct";
import { Button } from "@mui/material";

const ProductList = ({ products, name, disabled = false, hideButton=false }) => {
  const scrollContainerRef = useRef(null);
  const history = useHistory();

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: -200,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 200,
        behavior: "smooth",
      });
    }
  };

  const handleViewMore = () => {
    history.push(`/products?category=${encodeURIComponent(name)}`);
  };
  return (
    <div className="relative mb-5 mt-5">
      {products.length > 0 ? (
        <div className="relative">
          <div className="flex justify-between items-center mb-4">
            <h2 className="text-2xl font-bold">{name}</h2>
            <div className="flex space-x-2 items-center">
              <button
                onClick={scrollLeft}
                className="p-2 rounded-full bg-gray-300 hover:bg-gray-400"
              >
                <FaChevronLeft />
              </button>
              <button
                onClick={scrollRight}
                className="p-2 rounded-full bg-gray-300 hover:bg-gray-400"
              >
                <FaChevronRight />
              </button>
              {!hideButton && (
                <Button
                  onClick={() => handleViewMore()}
                  className="text-black-500 hover:underline"
                >
                  VIEW MORE
                </Button>
              )}
            </div>
          </div>
          <main
            ref={scrollContainerRef}
            className="flex gap-6 overflow-x-auto overflow-y-hidden scroll-smooth"
          >
            {products.map((product) => (
              <div key={product.product_id} className="flex-shrink-0 w-64">
                <SingleProduct
                  product={{
                    _id: product.product_id,
                    qty: 0,
                    name: product.title,
                    description: product.description,
                    brand: product.category,
                    type: product.product_type,
                    category: "Sports",
                    gender: "Men",
                    weight: "200g",
                    quantity: product.qty_available,
                    image: product.product_images[0],
                    rating: 4.5,
                    price: product.mrp,
                    newPrice: product.selling_price,
                    trending: true,
                    label: product.label,
                  }}
                  disabled={disabled}
                />
              </div>
            ))}
          </main>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProductList;
