import React, { useState } from "react";

const AddressCard = ({
  address,
  isEdit,
  showInput,
  editAddress,
  setEditAddress,
  setShowAddressForm,
  onAddressSelect,
}) => {
  const { address_id, full_name, mobile_no, street1, street2, city, pincode, type } =
    address;

  const handleAddressSelect = () => {
    onAddressSelect(address);
  };


  return (
    <label
      className={`flex "bg-gray-50" items-center gap-2 shadow-sm p-4 rounded-sm cursor-pointer`}
    >
      {showInput && (
        <input
          type="radio"
          name="address"
          id=""
          onChange={handleAddressSelect}
        />
      )}
      <div>
        <h3 className="text-lg font-semibold break-all">{full_name}</h3>
        <h3 className="text-md text-gray-500 break-all">{type}</h3>

        <p className="text-sm text-gray-500 break-all">
          {street1},{street2}
        </p>
        <p className="text-sm text-gray-500 break-all">
          {city},{pincode}
        </p>
        <p className="text-sm text-gray-500">
          Mobile:
          <span className="font-semibold ps-1 break-all">{mobile_no}</span>
        </p>
        {isEdit && (
          <div className="flex gap-3 py-2">
            <button
              className="text-amber-500 font-bold"
              onClick={() => {
                setEditAddress(address);
                setShowAddressForm(true);
              }}
            >
              Edit
            </button>
            <button className="text-red-600 font-bold">Remove</button>
          </div>
        )}
      </div>
    </label>
  );
};
export default AddressCard;
