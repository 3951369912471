import React from "react";

const TermsOfUse = () => {
  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center px-4">
      <div className="max-w-4xl bg-white rounded-lg shadow-lg p-8 m-5">
        <h1 className="text-3xl font-bold mb-6">Terms of Use</h1>
        <p className="mb-4">
          Welcome to Pancham Records! Please read these terms and conditions of
          use ("agreement" or "terms of use") carefully before using the website
          and services offered by Pancham Records. This agreement sets forth the
          legally binding terms and conditions for your use of the website
          www.panchamrecords.com ("the site") and all services provided on said
          site.
        </p>
        <p className="mb-4">
          By using the site in any manner including but not limited to visiting
          or browsing the site, you (the "user" or "you") agree to be bound by
          this Agreement in its entirety including the additional terms and
          conditions and policies referenced therein.
        </p>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Product Representation</h2>
          <p className="mb-4">
            While every effort has been made to accurately represent colors,
            customers must bear in mind that minor variations may occur in
            comparison with the actual product when viewed on a computer/mobile
            screen. This is typically due to variances in lighting and must be
            kept in mind when a product is ordered. Additionally, we are dealers
            / resellers of the products, we have no control over Manufacturing
            and imperfections caused during the same.
            </p>
          <p className="mb-4">
            The "Test Transaction Product" in the website is designed solely for testing and demonstration purposes.
             By purchasing this product, you agree that no physical or digital goods will be delivered. This product is 
             intended for testing transaction processes, such as payment gateways, and is not a tangible item or service.
              There will be no shipment or fulfillment of any kind associated with this product.
          </p>
          <p className="mb-4">
            Availability and price of products is subject to change without
            notice. Posted prices include taxes and charges unless otherwise
            mentioned.
          </p>
          <p className="mb-4">
            "Pancham Records" reserves the right to decline an order.
          </p>
          <p className="mb-4">
            Users hereby agree to act accordingly with local, national and
            international laws regarding acceptable content, commerce and
            conduct.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Registered Users</h2>
          <p className="mb-4">
            You can become a registered user of the site by creating an account
            with us. This feature is provided for your convenience. If you chose
            to register with us, you are responsible for providing accurate
            personal information during the registration process. You agree to
            accept responsibility for all activities that occur under your
            account or password.<br /><br />
            "This website is for sale to end users only. If you are a Reseller or Business Customer, please do not register."</p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Electronic Communication</h2>
          <p className="mb-4">
            When you visit www.panchamrecords.com or send e-mails to us, you are
            communicating with us electronically. By communicating with us, you
            consent to receive communication from us electronically. We will
            communicate with you by e-mail or by posting notices on our website.
            You agree that all agreements, notices, disclosures, and other
            communications that we provide to you electronically satisfy any
            legal requirement that such communication be in writing.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">User Contributed Content</h2>
          <p className="mb-4">
            <strong>Nature of Content:</strong> Visitors to the site may post
            reviews and comments and submit suggestions, ideas, comments,
            questions or other information, as long as the content is not
            illegal, obscene, threatening, defamatory, invasive of privacy,
            infringing of intellectual property rights or otherwise
            objectionable and does not consist of or contain software virus,
            political campaigning, commercial solicitation, mass mailing or any
            form of spam. Pancham Records has the right but not the obligation
            to monitor and edit or remove any activity or content that is deemed
            harmful or malignant. Pancham Records takes no responsibility and
            assumes no liability for any content posted by you or any third
            party.
          </p>
          <p className="mb-4">
            <strong>False Information:</strong> You may not use false
            information or impersonate any person or entity or provide false
            payment information.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">Online Payment</h2>
          <p className="mb-4">
            We as a merchant shall be under no liability whatsoever in respect
            of any loss or damage arising directly or indirectly out of the
            decline of authorization for any Transaction, on Account of the
            Cardholder having exceeded the preset limit mutually agreed by us
            with our acquiring bank from time to time.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">COPYRIGHT INFORMATION</h2>
          <p className="mb-4">
            This site is owned and operated by Pancham Records. Unless otherwise
            specified, all materials appearing on this site, including the text,
            site design, logos, graphics, icons, and images, as well as the
            selection, assembly and arrangement thereof, are the sole property
            of Pancham Records. All software used on the site is the sole
            property of Pancham Records or those supplying the software. Pancham
            Records grants you a limited license to access this website for
            personal use. You may use the content of this site only for the
            purpose of shopping on this site or placing an order on this site
            and for no other purpose. No materials from this site may be copied,
            reproduced, modified, republished, uploaded, posted, transmitted, or
            distributed in any form or by any means without Pancham Records
            prior written permission. All rights not expressly granted herein
            are reserved. Any unauthorized use of the materials appearing on
            this site may violate copyright, trademark and other applicable laws
            and could result in criminal or civil penalties.
          </p>
          <p className="mb-4">
            The use of any Pancham Records logo without Pancham Records's
            written consent is strictly prohibited.
          </p>
        </div>

        <div className="mb-8">
          <h2 className="text-2xl font-bold mb-4">
            DISCLAIMER AND LIMITATION OF LIABILITY
          </h2>
          <p className="mb-4">
            Except as expressly stated herein, Pancham Records makes no
            warranties, either express or implied, of any kind with respect to
            the content and products sold on the site. Pancham Records expressly
            disclaims all warranties, express or implied, of any kind with
            respect to the site or its use, including but not limited to
            merchantability and fitness of purpose. Pancham Records does not
            guarantee that the product description or any other content on the
            site is accurate, complete, reliable and current or error free. You
            agree that the sole and maximum liability or Pancham Records arising
            from any product sold on the site shall be the price of the ordered
            product. If the product offered by Pancham Records is not as
            described, its sole remedy is to return in its unused condition.
            Under no circumstances shall Pancham Records or its representatives
            be liable for special, indirect, consequential or punitive damages
            related to product sold or from the operation, content and use of
            Pancham Records site.
          </p>
          <h2 className="text-2xl font-bold mb-4">Cancellation Policy</h2>
          <p className="mb-4">
            <strong>Cancellation by Pancham Records:</strong> There may be
            certain orders that Pancham Records is unable to accept and must
            cancel. Pancham Records reserves the right, at Pancham Records's
            sole discretion, to refuse or cancel any order for any reason
            whatsoever. Some situations that may result in Your order being
            cancelled include, without limitation, non-availability of the
            product or quantities ordered by You, non- availability of the
            service, inaccuracies or errors in pricing information, or problems
            identified by Pancham Records's credit and fraud avoidance
            department. Pancham Records may also require additional
            verifications or information before accepting any order. Pancham
            Records will contact You if all or any portion of Your order is
            cancelled or if additional information is required to accept Your
            order. If Your order is cancelled after Your credit card has been
            charged, the said amount will be reversed back in Your credit card
            account. A promo code, once used shall not be refunded in case of
            cancellation of order either by Customer or Pancham Records.
          </p>
          <p className="mb-4">
            <strong>Cancellation by the User:</strong> In case of requests for
            order cancellations, Pancham Records reserves the right to accept or
            reject requests for order cancellations for any reason whatsoever.
            As part of usual business practice, if Pancham Records receives a
            cancellation notice and the order has not been processed/ approved
            by Pancham Records, Pancham Records shall cancel the order and
            refund the entire amount to You within a reasonable period of time.
            Pancham Records will not be able to cancel orders that have already
            been processed. Pancham Records has the full right to decide whether
            an order has been processed or not. You agree not to dispute the
            decision made by Pancham Records and accept Pancham Records's
            decision regarding the cancellation.
          </p>
          <p className="mb-4">
            Pancham Records reserves the right to cancel any orders that
            classify as 'Bulk Order' as determined by Pancham Records as per
            certain criteria. Any Pancham Records Reward Point or Promo Code
            used for placing the 'Bulk Order' will not be refunded as per this
            cancellation policy. An order can be classified as 'Bulk Order' if
            it meets with the below mentioned criteria, which may not be
            exhaustive, viz:
          </p>
          <ol className="list-decimal list-inside mb-4">
            <li>
              Products ordered are not for self-consumption but for commercial
              resale
            </li>
            <li>Multiple orders placed for same product at the same address</li>
            <li>Bulk quantity of the same product ordered</li>
            <li>Invalid address given in order details</li>
            <li>Any malpractice used to place the order</li>
          </ol>
          <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>
          <p className="mb-4">
            This Privacy policy is subject to the terms of the Site Policy (User
            agreement) of Pancham Records. This policy is effective from the
            date and time a user registers with Pancham Records by filling up
            the Registration form and accepting the terms and conditions laid
            out in the Site Policy.
          </p>
          <p className="mb-4">
            In order to provide a personalised browsing experience, Pancham
            Records may collect personal information from you. Additionally our
            website may require you to complete a registration form or seek some
            information from you. When you let us have your preferences, we will
            be able to deliver or allow you to access the most relevant
            information that meets your end.
          </p>
          <p className="mb-4">
            "By using the website and/or registering yourself with us you
            authorise us to contact you via email or phone call or sms and offer
            you our services, imparting product knowledge, offer promotional
            offers running on website & offers offered by third parties, for
            which reasons, personally identifiable information may be collected.
            And irrespective of the fact if also you have registered yourself
            under DND or DNC or NCPR service, you still authorise us to give you
            a call from Pancham Records / www.panchamrecords.com for the above
            mentioned purposes till 365 days of your registration with us."
          </p>
          <p className="mb-4">
            To extend this personalized experience Pancham Records may track the
            IP address of a user's computer and save certain information on your
            system in the form of cookies. A user has the option of accepting or
            declining the cookies of this website by changing the settings of
            your browser.
          </p>
          <p className="mb-4">
            The personal information provided by the users to Pancham Records
            will not be provided to third parties without previous consent of
            the user concerned. Information of a general nature may however be
            revealed to external parties
          </p>
          <p className="mb-4">
            Every effort will be made to keep the information provided by users
            in a safe manner, the information will be displayed on the website
            will be done so only after obtaining consent from the users. Any
            user browsing the site generally is not required to disclose his
            identity or provide any information about him/her, it is only at the
            time of registration you will be required to furnish the details in
            the registration form.
          </p>
          <p className="mb-4">
            A full user always has the option of not providing the information
            which is not mandatory. You are solely responsible for maintaining
            confidentiality of the User password and user identification and all
            activities and transmission performed by the User through his user
            identification and shall be solely responsible for carrying out any
            online or off-line transaction involving credit cards / debit cards
            or such other forms of instruments or documents for making such
            transactions and Pancham Records assumes no responsibility or
            liability for their improper use of information relating to such
            usage of credit cards / debit cards used by the subscriber online /
            off-line.
          </p>
          <p className="mb-4">
            You agree that Pancham Records may use personal information about
            you to improve its marketing and promotional efforts, to analyze
            site usage, improve the Site's content and product offerings, and
            customise the Site's content, layout, and services. These uses
            improve the Site and better tailor it to meet your needs, so as to
            provide you with a smooth, efficient, safe and customised experience
            while using the Site.
          </p>
          <p className="mb-4">
            You agree that Pancham Records may use your personal information to
            contact you and deliver information to you that, in some cases, are
            targeted to your interests, such as targeted banner advertisements,
            administrative notices, product offerings, and communications
            relevant to your use of the Site. By accepting the User Agreement
            and Privacy Policy, you expressly agree to receive this information.
            If you do not wish to receive these communications, we encourage you
            to opt out of the receipt of certain communications in your profile.
            You may make changes to your profile at any time.
          </p>
          <p className="mb-4">
            It is the belief of Pancham Records that privacy of a person can be
            best guaranteed by working in conjunction with the Law enforcement
            authorities.
          </p>
          <p className="mb-4">
            www.panchamrecords.com website is fully comply with all Indian Laws
            applicable. Pancham Records has always cooperated with all law
            enforcement inquires. Pancham Records may disclose all or part of
            your personal details in response to a request from the law
            enforcement authorities or in a case of bonafide requirement to
            prevent an imminent breach of the law.
          </p>
          <h1 className="text-3xl font-bold mb-6">Return Policy</h1>
          <p className="mb-4">
            In the event You receive a damaged / defective product or a product
            that does not comply with the specifications as per your original
            order, You are required to get in touch with the customer service
            team through any of the below mentioned channels:
          </p>
          <p className="mb-4">
            Contact Us : +919422577724, +919422020363, Mail –
            panchamrecords@gmail.com
          </p>
          <p className="mb-4">
            Upon receiving Your complaint, Pancham Records shall verify the
            authenticity and the nature of the complaint and if we are convinced
            that the complaint is genuine, Pancham Records will inform the
            relevant Vendor of such complaint and request for a replacement.
            However, in the event of frivolous and baseless complaints regarding
            the quality and content of the products, Pancham Records reserves
            the right to take necessary legal actions against You and You will
            be solely liable for all costs incurred by Pancham Records in this
            regard. You expressly acknowledge that the Vendor selling the
            defective product/ service will be solely responsible to You for any
            claims that You may have in relation to such defective product/
            service and Pancham Records shall not in any manner be held liable
            for the same.
          </p>
          <p className="mb-4">
            Before accepting shipment of any product, kindly ensure that the
            product's packaging is not damaged or tampered. If You observe that
            the package is damaged or tampered, request You to refuse to accept
            delivery and inform Pancham Records at the earliest. The return
            process of the product may be restricted by Pancham Records
            depending on the nature and category of the product.
          </p>
          <p className="mb-4">
            In order to return any products sold through the Website, You are
            required to comply with the below mentioned conditions, viz:
          </p>
          <ol className="list-decimal list-inside mb-4">
            <li>
              Please notify Pancham Records of receipt of a damaged/ defective
              product/service within 48-hours of delivery to You. If You are
              unable to do so within 48-hours, Pancham Records shall not be held
              liable for the failure to replace the order;
            </li>
            <li>Products should be unused;</li>
            <li>
              Pancham Records will arrange pick-up of the damaged/defective
              product through its own logistics partner. In the event Pancham
              Records is unable to do so, Pancham Records will notify You
              regarding the same and You will be required to dispatch the
              product using a reputed courier in Your respective area within one
              (1) day from receipt of such notice. Courier freight charges will
              be reimbursed in such form as is determined by the logistics team
              upon prior consultation with Pancham Records;
            </li>
            <li>
              Products should be returned in their original packaging along with
              the original price tags, labels, barcodes, user manual, warranty
              card and invoices etc.;
            </li>
            <li>
              It is advised that the return packets should be strongly and
              adequately packaged so that there is no further damage of products
              during transit;
            </li>
            <li>
              The returned products are subject to verification and checks by
              Pancham Records in order to determine the legitimacy of the
              complaint/ return.
            </li>
          </ol>
          <p className="mb-4">
            In the event the return of a product is duly accepted by Pancham
            Records, the value of such product, as originally paid by You during
            acceptance of delivery of product or otherwise, will be refunded to
            You. Refund will be processed based on the mode of payment and
            Pancham Records or the approved payment gateway will credit Your
            refunds directly into Your debit/credit card or online accounts or
            provide You with a cheque in this regard. Refunds will be subject to
            the following:
          </p>
          <ol className="list-decimal list-inside mb-4">
            <li>
              Orders paid online will be refunded within 7-21 working days
              through the online account or via cheque, depending on the then
              current circumstances as determined by Pancham Records;
            </li>
            <li>
              For Cash on Delivery payments, You will be provided with a refund
              cheque.
            </li>
            <li>
              If the product can be repaired by the service centre, Pancham
              Records would get the same repaired and send it back to You.
            </li>
            <li>
              A promo code, once used shall not be refunded in case of
              cancellation of order either by customer or Pancham Records.
            </li>
          </ol>
          <h1 className="text-3xl font-bold mb-6">Dispatch and Delivery</h1>
          <p className="mb-4">
            Dispatch will be done within 3 working days after successful
            confirmation of payment . All dispatches will be done by DTDC
            couriers by Air , unless the delivery pincode is not serviceable by
            dtdc . In that case other courier service will be used and it will
            be mentioned on the status page of the order . Delivery time for
            DTDC by air for all over india is 8 - 10 days after dispatch . And
            in case of Surface courier will be 15-17 days . If the package
            contains any liquid product , it will be dispatched by Surface
            courier only.
          </p>
          <h1 className="text-3xl font-bold mb-6">About Us</h1>
          <p className="mb-4">
            Pancham Records is a online one stop shop for all the types of
            Physical Audio Media , be it Analog or Digital . Based in Pune /
            Jalna in Maharashtra - India, Pancham Records offers customers
            Gramophone Records and all other media with true images of the
            product,and proper grading so the buyer has an exact idea of what he
            is buying . We Offer media in a wide range of Genre , procured from
            famous and knowledgeable sellers across India
          </p>
          <p className="mb-4">
            The idea of an online shop incurred to us when we felt that most of
            the genuine sellers across india weren’t capable to sell online ,
            and that we could buy from those sellers and sell online. we found
            many potential buyers from India and abroad were still in search of
            a genuine platform to fullfill their requirements . Being an aRDent
            Pancham aka Rahul Dev Burman Fan , Pancham Records was born and we
            have been selling Gramophone records and other media online via
            social media platforms since 2023 .
          </p>

          <div className="mb-8">
            <p className="mb-4">
              Whether you’re a seasoned collector or just starting your vinyl
              journey, Pancham Records is the perfect place to fill your
              wishlist .
            </p>
            <p className="mb-4">
              We are sure that once you buy from us , you will never need to go
              anywhere else . We wish to add Audio Equipments and Accessories in
              future .
            </p>
            <p className="mb-4">Sheetal Praveen Jaiswal </p>
            <p className="mb-4">Founder</p>
          </div>
          <div className="flex justify-center ">
            <div className="max-w-4xl p-8">
              <h1 className="text-3xl font-bold mb-6 text-center">
                Contact Us
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-gray-100 rounded-lg p-6 flex flex-col items-center">
                  <h2 className="text-xl font-bold mb-2">Phone</h2>
                  <p className="mb-2 text-gray-600">+919422577724</p>
                  <p className="text-gray-600">+919422020363</p>
                </div>
                <div className="bg-gray-100 rounded-lg p-6 flex flex-col items-center">
                  <h2 className="text-xl font-bold mb-2">Email</h2>
                  <p className="text-gray-600">sales@panchamrecords.com</p>
                  <p className="text-gray-600">panchamrecords@gmail.com</p>
                </div>
                <div className="bg-gray-100 rounded-lg p-6 flex flex-col items-center">
                  <h2 className="text-xl font-bold mb-2">Pancham Records</h2>
                  <p className="text-gray-600">
                    302 , Kailash Vidya Residency ,
                  </p>
                  <p className="text-gray-600">
                    Mahalaxmi Nagar , Mantha Chaufully ,
                  </p>
                  <p className="text-gray-600">
                    Jalna – 431203 ( Maharashtra )
                  </p>
                </div>
                <div className="bg-gray-100 rounded-lg p-6 flex flex-col items-center">
                  <h2 className="text-xl font-bold mb-2">Dispatch Office</h2>
                  <p className="text-gray-600">Pancham Records</p>
                  <p className="text-gray-600">Khardekar Complex,</p>
                  <p className="text-gray-600">Opp Mission Hospital,</p>
                  <p className="text-gray-600">
                    Jalna – 431203 ( Maharashtra )
                  </p>
                </div>
                <div className="bg-gray-100 rounded-lg p-6 flex flex-col items-center">
                  <h2 className="text-xl font-bold mb-2">
                    Grievances / Complaints
                  </h2>
                  <p className="text-gray-600">praveen@panchamrecords.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsOfUse;
