import React, { useState, useEffect } from "react";
import Address from "../components/address/Address";
import { useHistory } from "react-router";
import axios from "axios";
import { useSnackbar } from "notistack";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import BackButton from "../components/BackButton";


const MyAddress = () => {
    const [selectedAddressId, setSelectedAddressId] = useState(null);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const handleAddressSelect = (id) => {
    setSelectedAddressId(id);
  };

  return (
    <PageWrapper>
       <BackButton />
      <div className="py-4">
         <h1 className="text-2xl font-bold mb-6">My Address</h1>
        <Address onAddressSelect={handleAddressSelect} showRadio={false} />
      </div>
    </PageWrapper>
  );
};

export default MyAddress;
