import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { BsBookmarkHeart } from "react-icons/bs";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { MdOutlineExplore } from "react-icons/md";
import { useHistory } from "react-router";
import { RxHamburgerMenu } from "react-icons/rx";
import Logo from "./Logo";
import Search from "../filters/Search";
import MenuDropdown from "./MenuDropdown";
const Navbar = () => {
//   const { token } = useAuthContext();
//   const { cart } = useCartContext();
//   const { wishlist } = useWishlistContext();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [colorChange, setColorChange] = useState(true);
  const changeNavbarColor = () => {
    if (window.scrollY >= 80) {
      setColorChange(true);
    } else {
      setColorChange(false);
    }
  };
  const history = useHistory();

  useEffect(() => {
    window.addEventListener("scroll", changeNavbarColor);

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, []);

  return (
    <nav
      className={`flex flex-col sm:flex-row py-1 max-w-screen mb-3 fixed left-0 right-0 px-[4%] md:px-[10%] bg-[--theme-color] ${
        colorChange ? "shadow-sm  drop-shadow-sm" : ""
      } z-10 transition delay-75 ease-in-out`}
    >
      <div className="flex justify-between w-full items-center">
        <section className="relative flex items-center">
          <Logo />
        </section>
        <div className="hidden  sm:block sm:w-1/3 relative">
          <Search />
        </div>

        <section className="flex items-center">
          <Link
            to="/products"
            className="mx-2 px-3 py-1 shadow-sm rounded-md text-white bg-yellow-700 text-sm hover:bg-yellow-800 transition"
          >
            <span className="hidden xs:block">All Products</span>{" "}
            {/* <span className="hidden xs:block">Explore</span>{" "} */}
            <MdOutlineExplore className="xs:hidden" />
          </Link>

          <Link
            to="/account"
            className="mx-2 px-3 py-1 shadow-sm rounded-md text-white bg-yellow-700 text-sm hover:bg-yellow-800 transition"
          >
      
            <span className="hidden xs:block">      {localStorage.getItem("userId") ? "Account": "Login"}</span>{" "}
            <MdOutlineExplore className="xs:hidden" />
          </Link>
          <ul className=" md:flex justify-between text-2xl ps-1">
            {/* <li
              className="relative bg-gray-200  p-2 rounded-full hover:bg-yellow-800 hover:text-white cursor-pointer mx-2 transition shadow-sm"
              onClick={() => history.go("/wishlist")}
            >
              <BsBookmarkHeart /> */}
            {/* {token && wishlist.length > 0 && (
                <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-rose-600 border-2 border-[--theme-color] rounded-full -top-2 -right-2 ">
                  {wishlist.length}
                </div>
              )} */}
            {/* </li> */}
            <li
              className="relative bg-yellow-500 text-white p-2 rounded-full hover:bg-yellow-800 cursor-pointer mx-2 transition shadow-sm"
              onClick={() => history.push("/cart")}
            >
              <HiOutlineShoppingBag />
              {/* {token && cart.length > 0 && (
                <div className="absolute inline-flex items-center justify-center w-6 h-6 text-xs font-bold text-white bg-rose-600 border-2 border-[--theme-color] rounded-full -top-2 -right-2 ">
                  {cart.length}
                </div>
              )} */}
            </li>
          </ul>
          

          {/* <section className="md:hidden cursor-pointer relative">
            <RxHamburgerMenu
              className="text-lg"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
            {isMenuOpen && <MenuDropdown/>}
          </section> */}
        </section>
      </div>

      <section className="mt-4 sm:hidden relative">
        <Search />
      </section>
    </nav>
  );
};

export default Navbar;
