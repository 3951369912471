import axios from "axios";

const getUserId = () => {
  try {
    const userData = JSON.parse(localStorage.getItem("userData"));
    return userData?.user_id || null;
  } catch (error) {
    console.error("Error parsing userData from localStorage:", error);
    return null;
  }
};

const getActiveCartId = () => localStorage.getItem("activeCartId") || null;
const getAuthToken = () => localStorage.getItem("authToken") || "";

const createAxiosInstance = () => {
  const authToken = getAuthToken();
  return axios.create({
    baseURL: process.env.REACT_APP_BASEURL,
    headers: authToken ? { authorization: `Bearer ${authToken}` } : {},
  });
};

const handleApiError = (error, functionName) => {
  console.error(`Error in ${functionName}:`, error);
  throw error;
};

export const postInitializeCartService = async () => {
  try {
    const userId = getUserId();
    if (!userId) throw new Error("User ID not available");

    const axiosInstance = createAxiosInstance();
    return await axiosInstance.post("/order/getinitialdata", {
      user_id: userId,
    });
  } catch (error) {
    handleApiError(error, "postInitializeCartService");
  }
};

export const postAddProductToCartService = async (data) => {
  try {
    const userId = getUserId();
    if (!userId) throw new Error("User ID not available");

    const initResponse = await postInitializeCartService();
    const cartId = initResponse?.data?.data?.cart?.order_id || null;

    const axiosInstance = createAxiosInstance();
    const endpoint = cartId ? "/order/update" : "/order/add";
    const method = cartId ? "put" : "post";
    const payload = {
      ...data,
      user_id: userId,
      status: "cart",
      ...(cartId && { order_id: cartId }),
    };

    return await axiosInstance[method](endpoint, payload);
  } catch (error) {
    handleApiError(error, "postAddProductToCartService");
  }
};

export const postRemoveProductFromCart = async (data) => {
  try {
    const userId = getUserId();
    const activeCartId = getActiveCartId();
    if (!userId || !activeCartId)
      throw new Error("User ID or active cart ID not available");

    const axiosInstance = createAxiosInstance();
    return await axiosInstance.put("/order/update", {
      ...data,
      user_id: userId,
      order_id: activeCartId,
      status: "cart",
    });
  } catch (error) {
    handleApiError(error, "postRemoveProductFromCart");
  }
};

export const addAddress = async (data) => {
  try {
    const userId = getUserId();
    if (!userId) throw new Error("User ID not available");

    const axiosInstance = createAxiosInstance();
    return await axiosInstance.post("/users/addaddress", {
      ...data,
      user_id: userId,
    });
  } catch (error) {
    handleApiError(error, "addAddress");
  }
};

export const getAddresses = async () => {
  try {
    const userId = getUserId();
    if (!userId) throw new Error("User ID not available");

    const axiosInstance = createAxiosInstance();
    return await axiosInstance.post("/users/getaddress", { user_id: userId });
  } catch (error) {
    handleApiError(error, "getAddresses");
  }
};

export const getinitialdata = async () => {
  try {
    const userId = getUserId();
    if (!userId) throw new Error("User ID not available");

    const axiosInstance = createAxiosInstance();
    return await axiosInstance.post("/order/getinitialdata", {
      user_id: userId,
    });
  } catch (error) {
    handleApiError(error, "getinitialdata");
  }
};

export const postCreateOrder = async (data) => {
  try {
    const userId = getUserId();
    if (!userId) throw new Error("User ID not available");

    const initResponse = await postInitializeCartService();
    const cartId = initResponse?.data?.data?.cart?.order_id;
    if (!cartId) throw new Error("Cart ID not available");

    const axiosInstance = createAxiosInstance();
    return await axiosInstance.put("/order/updateorderaddress", {
      ...data,
      user_id: userId,
      order_id: cartId,
    });
  } catch (error) {
    handleApiError(error, "postCreateOrder");
  }
};

export const postCreateShipments = async (data) => {
  try {
    const userId = getUserId();
    if (!userId) throw new Error("User ID not available");

    const axiosInstance = createAxiosInstance();
    return await axiosInstance.post("/order/addshipment", {
      ...data,
      user_id: userId,
      order_id: parseInt(data.order_id || "50", 10),
    });
  } catch (error) {
    handleApiError(error, "postCreateShipments");
  }
};
