import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import axios from "axios";
import { useSnackbar } from "notistack";
import PageWrapper from "../components/PageWrapper/PageWrapper";
import { FaAngleRight, FaPencilAlt, FaTimes } from "react-icons/fa";
import BackButton from "../components/BackButton";


const Account = () => {
  const [account, setAccount] = useState(null);
  const [editable, setEditable] = useState(false);
  const [name, setName] = useState("");
  const [originalName, setOriginalName] = useState("");
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const auth = localStorage.getItem("authToken");
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    if (userId){
getAccount();
    } 
    else {
      history.push("/login");
    }
  }, []);

  const getAccount = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASEURL}/users/getuser`,
        {
          user_id: userId,
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      if (response.data.success) {
        setAccount(response.data.data);
        setName(response.data.data.name);
        setOriginalName(response.data.data.name);
      }
    } catch (error) {
      console.error("Error occurred while fetching account details:", error);
    }
  };

  const handleUpdate = async () => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_BASEURL}/users/updatedetails`,
        {
          user_id: userId,
          name: name,
          address: "A",
        },
        {
          headers: {
            Authorization: `Bearer ${auth}`,
          },
        }
      );
      if (response.data.success) {
        enqueueSnackbar("Name updated successfully", { variant: "success" });
        setEditable(false);
        setOriginalName(name);
      } else {
        enqueueSnackbar("Failed to update name", { variant: "error" });
      }
    } catch (error) {
      console.error("Error occurred while updating name:", error);
      enqueueSnackbar("Error occurred. Please try again.", {
        variant: "error",
      });
    }
  };

  const handleNameClick = () => {
    setEditable(true);
  };

  const handleCancel = () => {
    setEditable(false);
    setName(originalName);
  };


  return (
    <PageWrapper>
       <BackButton />
      <div className="py-2">
        <h1 className="text-2xl font-bold p-3">My Account</h1>
        {account && (
          <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
            <div className="mb-4 flex items-center justify-between">
              <div className="flex items-center">
                <label className="block text-gray-700 text-sm font-bold mr-2">
                  Name:
                </label>
                {!editable && (
                  <p
                    className="text-gray-700 cursor-pointer"
                    onClick={handleNameClick}
                  >
                    {name}
                  </p>
                )}
              </div>

              {editable ? (
                <>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  />
                  <button
                    onClick={handleCancel}
                    className="ml-2"
                    title="Cancel"
                  >
                    <FaTimes />
                  </button>
                </>
              ) : (
                <div className="flex items-center">
                  <FaPencilAlt
                    className="ml-1 cursor-pointer"
                    onClick={handleNameClick}
                  />
                </div>
              )}
            </div>
            {editable && (
              <div className="flex items-center justify-end">
                <button
                  onClick={handleUpdate}
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                >
                  Update
                </button>
              </div>
            )}
            <div className="flex items-center">
              <label className="block text-gray-700 text-sm font-bold mr-2">
                Phone No:
              </label>

              <div className="flex items-center">
                <p className="text-gray-700">{account.phone_no}</p>
              </div>
            </div>
          </div>
        )}
      </div>

      <div
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex items-center justify-between cursor-pointer"
        onClick={() => history.push("/orders")}
      >
        <p className="text-lg font-semibold">My Orders</p>

        <FaAngleRight className="text-gray-600 cursor-pointer hover:text-gray-900" />
      </div>
      <div
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex items-center justify-between cursor-pointer"
        onClick={() => history.push("/my-address")}
      >
        <p className="text-lg font-semibold">My Address</p>

        <FaAngleRight className="text-gray-600 cursor-pointer hover:text-gray-900" />
      </div>
      <div
        className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex items-center justify-between cursor-pointer"
        onClick={() =>{ localStorage.clear();history.push("/login")}}
      >
        <p className="text-lg font-semibold">Logout</p>

        <FaAngleRight className="text-gray-600 cursor-pointer hover:text-gray-900" />
      </div>
    </PageWrapper>
  );
};

export default Account;
