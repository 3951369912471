import React, { Fragment, useState, useEffect } from "react";
import AddressCard from "./AddressCard";
import AddressForm from "./AddressForm";
import axios from "axios";
import { getAddresses } from "../../api/apiServices";

const Address = ({ isEdit, onAddressSelect, showRadio }) => {
  const [showAddressForm, setShowAddressForm] = useState(false);
  const [editAddress, setEditAddress] = useState(null);
  const [addresses, setAddresses] = useState(null);

  useEffect(() => {
    const fetchAddresses = async () => {
      try {
        const response = await getAddresses();
        setAddresses(response.data.data);
      } catch (error) {
        console.error("Error fetching addresses:", error);
      }
    };

    fetchAddresses();
  }, [showAddressForm]);

  const handleFormSubmitSuccess = () => {
    setShowAddressForm(false);
  };

   const handleAddressSelect = (address) => {
     onAddressSelect(address);
   }; 

  return (
    <>
      {!isEdit && <h1 className="text-2xl font-bold">Address</h1>}
      {showAddressForm && !editAddress ? (
        <AddressForm
          setShowAddressForm={setShowAddressForm}
          editAddress={editAddress}
          setEditAddress={setEditAddress}
          onSuccess={handleFormSubmitSuccess}
        />
      ) : (
        <div className="flex flex-col items-start">
          <button
            className="btn-rounded-primary text-sm"
            onClick={() => {
              setShowAddressForm(true);
              setEditAddress(false);
            }}
          >
            + Add New Address
          </button>
        </div>
      )}
      <div className="flex flex-col gap-2">
        {Array.isArray(addresses) &&
          addresses?.map((address) => (
            <Fragment key={address.address_id}>
              <AddressCard
                address={address}
                showInput={showRadio}
                isEdit={isEdit}
                editAddress={editAddress}
                setEditAddress={setEditAddress}
                setShowAddressForm={setShowAddressForm}
                onAddressSelect={handleAddressSelect}
              />
            </Fragment>
          ))}
      </div>
    </>
  );
};

export default Address;
