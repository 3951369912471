import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import axios from "axios";

import bannerHero from "../assets/loginbanner.jpg";
import MainLogo from "../imgs/main-logo.png";
import Footer from "../components/footer/Footer";
import { getAddresses } from "../api/apiServices";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
const BASE_URL = process.env.REACT_APP_BASEURL;

const Signup = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [phoneNo, setPhoneNo] = useState("");
  const [name, setName] = useState("");
  const [otp, setOtp] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [inactiveModal, setInactiveModal] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

   const handleClickInactiveModal = () => {
     setInactiveModal(true);
   };

   const handleCloseInactiveModal = () => {
     setInactiveModal(false);
   };


  const handleRequest = async (url, data, successMsg, errorMsg) => {
    setIsLoading(true);
    try {
      const res = await axios.post(`${BASE_URL}${url}`, data);
      if (res.data.success) {
        enqueueSnackbar(successMsg, { variant: "success" });
        return res.data;
      } else if (
        !res.data.success &&
        res.data.message === "user already register with us."
      ) {
        handleClickOpen();
      } else if (
        res.data.message ===
          "Registration Request Successful!! You will be able to Login and Order once request is approved by Admin."
      ) {
        handleClickInactiveModal();
      } else {
        throw new Error(res.data.message || errorMsg);
      }
    } catch (err) {
      enqueueSnackbar(err.message || errorMsg, { variant: "error" });
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const sendOTP = async (e) => {
    e.preventDefault();
    const result = await handleRequest(
      "/users/signup",
      { phone_no: phoneNo, name: name },
      "OTP sent successfully",
      "Failed to send OTP"
    );
    if (result) setIsOtpSent(true);
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    const result = await handleRequest(
      "/users/verifyotp",
      { phone_no: phoneNo, otp },
      "OTP verified successfully",
      "Invalid OTP"
    );
    if (result) {
      const { data, auth } = result;
      localStorage.setItem("userData", JSON.stringify(data));
      localStorage.setItem("authToken", auth);
      localStorage.setItem("userId", data.user_id);
      try {
        const addressResponse = await getAddresses();
        const addresses = addressResponse.data.success;

        if (addresses) {
          setTimeout(() => history.replace("/"), 0);
        } else {
          history.replace("/addaddress");
        }
      } catch (error) {
        console.error("Error fetching addresses:", error);
        enqueueSnackbar(
          "Failed to fetch addresses. Redirecting to dashboard.",
          { variant: "warning" }
        );
        history.replace("/");
      }
    }
  };

  return (
    <>
      <main className="grid md:grid-cols-2 w-full h-screen m-auto">
        <Dialog
          open={inactiveModal}
          onClose={handleClickInactiveModal}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"sm"}
        >
          <DialogTitle id="alert-dialog-title">
            Registration Request Successful!!
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              You will be able to Login and Order once request is
              approved by Admin.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}>Disagree</Button> */}
            <Link to="/" className="text-blue-500 underline">
              <Button onClick={handleCloseInactiveModal} autoFocus>
                Explore Products
              </Button>
            </Link>
          </DialogActions>
        </Dialog>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth={"sm"}
        >
          <DialogTitle id="alert-dialog-title">
            {"You already have an account! "}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Please login instead
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={handleClose}>Disagree</Button> */}
            <Link to="/login" className="text-blue-500 underline">
              <Button onClick={handleClose} autoFocus>
                Login
              </Button>
            </Link>
          </DialogActions>
        </Dialog>
        <section className="hidden md:block max-h-screen rounded-lg">
          <img src={bannerHero} alt="Banner" className="w-full h-full" />
        </section>
        <div className="flex items-center justify-center w-full px-5">
          <section className="px-10 py-5 rounded-md shadow-xl bg-white/[0.7] flex flex-col gap-6 w-full max-w-lg">
            <div className="flex items-center justify-center w-full px-5">
              <img
                src={MainLogo}
                width="30%"
                alt="logo"
                className="object-center cursor-pointer"
                onClick={() => history.push("/")}
              />
            </div>

            <h1 className="text-3xl mb-3">
              {isOtpSent ? "Enter OTP" : "Sign Up"}
            </h1>

            <form
              onSubmit={isOtpSent ? verifyOTP : sendOTP}
              className="flex flex-col gap-4 py-5"
            >
              <label className="flex flex-col">
                {!isOtpSent && (
                  <>
                    <input
                      type="text"
                      required
                      placeholder="Enter Your Name"
                      className="border rounded-md p-1.5 shadow-sm bg-white"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                    <br />
                  </>
                )}
                <input
                  type={isOtpSent ? "number" : "tel"}
                  required
                  placeholder={isOtpSent ? "OTP" : "Phone Number"}
                  className="border rounded-md p-1.5 shadow-sm bg-white"
                  value={isOtpSent ? otp : phoneNo}
                  onChange={(e) =>
                    isOtpSent
                      ? setOtp(e.target.value)
                      : setPhoneNo(e.target.value.slice(0, 10))
                  }
                  inputMode="numeric"
                  maxLength={isOtpSent ? undefined : 10}
                />
              </label>

              {!isOtpSent && (
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="tnc-checkbox"
                    className="mr-2"
                    required
                  />
                  <label htmlFor="tnc-checkbox">
                    I accept{" "}
                    <Link
                      to="/tnc"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 underline"
                    >
                      Terms and Conditions
                    </Link>
                  </label>
                </div>
              )}

              <div className="w-full py-2 flex flex-col gap-4 items-center">
                <button
                  type="submit"
                  className="btn-primary w-2/3 text-lg text-center"
                  disabled={isLoading || (!isOtpSent && phoneNo.length < 10)}
                >
                  {isLoading
                    ? "Loading..."
                    : isOtpSent
                    ? "Verify OTP"
                    : "Send OTP"}
                </button>
              </div>
            </form>
            <p className="text-center">
              Already have an account?{" "}
              <Link to="/login" className="text-blue-500 underline">
                Login
              </Link>
            </p>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default Signup;
