import { GiRoundStar } from "react-icons/gi";
import { BsBookmarkHeart, BsFillBookmarkHeartFill } from "react-icons/bs";
import { useHistory } from "react-router";
import {
  postAddProductToCartService
} from "../../api/apiServices";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { useSnackbar } from "notistack";
import { Button } from "@mui/material";

const SingleProduct = ({ product, disabled }) => {
  console.log(product)
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const userId = localStorage.getItem("userId");
  const addProductToCart = async (product_id) => {
    console.log(product_id);
    const data = { product_id: parseInt(product_id), qty: 1 };


    postAddProductToCartService(data)
      .then((response) => {
        if (response.data.success) {
            enqueueSnackbar("Product Added to Cart", { variant: "success" });
          // history.push("/cart");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div
      className={`flex flex-col sm:flex-col bg-white/[0.5] rounded-lg shadow-md border-2 border-black/[0.05] overflow-hidden transition-transform ${disabled ? "pointer-events-none" : "cursor-pointer"
        }`}
    >
      <div
        className="flex items-center justify-center bg-black/[0.075] xs:w-1/2 w-full sm:w-full relative"
        style={{ width: "100%", paddingBottom: "100%", position: "relative" }}
        onClick={() => {
          history.push(`/products/${product._id}`);
        }}
      >
        <img
          src={`https://pancham-bucket.s3.amazonaws.com/${product.image}`}
          alt=""
          className="absolute inset-0 w-full h-full object-cover"
          style={{ objectFit: "cover" }}
        />
        {product.quantity <= 0 && (
          <div className="absolute top-0 right-0 bg-red-500 text-white px-2 py-1 m-2 rounded">
            Sold Out
          </div>
        )}
      </div>

      <div className="p-3 flex flex-col justify-between gap-2 mt-2 h-1/2 xs:h-full sm:h-1/2 xs:w-2/3 w-full sm:w-full">
        <div>
          <div className="flex justify-between">
            <div className="flex flex-col">
              <span className="text-xl font-medium">{product.name}</span>
              <span className="flex items-center gap-1">
                <span className="text-xs text-gray-400">{product.label}</span>
              </span>
            </div>

            <div className="flex flex-col items-end">
              <span className="text-amber-600">₹{product.newPrice}</span>
              {product.price > product.newPrice && (
                <span className="text-sm text-gray-600 line-through">
                  ₹{product.price}
                </span>
              )}
            </div>
          </div>
          {product.brand !== 'Liquid' && <p className="text-sm text-gray-600">{product.brand}</p>}
          {product.type !== 'Liquid' && <p className="text-sm text-gray-400">{product.type}</p>}
          <p className="text-sm text-gray-600">{product.description}</p>
        </div>
        <div className="w-full pt-2 border-t text-center">
          {/* Add to Cart button code here */}
          <button
            className={`btn-rounded-secondary flex items-center gap-2 text-sm ${
              product.quantity === 0 || !userId
                ? "disabled:cursor-not-allowed"
                : ""
            }`}
            disabled={product.quantity === 0}
            onClick={() => {
              if (!userId) {
                history.push("/login");
              } else if (product.quantity > 0) {
                addProductToCart(product._id);
              }
            }}
            fullWidth
          >
            {product.quantity <= 0 ? (
              "Sold Out"
            ) : !userId ? (
              "Login to Shop"
            ) : (
              <>
                <HiOutlineShoppingBag /> Add to Cart
              </>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
