import React from "react";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";

function PageWrapper({ children, hideNav }) {
  return (
    <>
      <div className="px-[4%] md:px-[10%] pb-2">
        {!hideNav && <Navbar />}
        <div className="pt-44 sm:pt-30">{children}</div>
      </div>
      <Footer />
    </>
  );
}

export default PageWrapper;
