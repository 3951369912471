import React from "react";
import PriceCard from "./PriceCard";
import { useHistory } from "react-router";
import { Alert } from "@mui/material";

const CartTotalCard = ({ cart }) => {

  const history = useHistory();

  const calculateTotalPriceSum = (cartItems) => {
    if (!cartItems || cartItems.length === 0) {
      return 0;
    }
    const totalPriceSum = cartItems.reduce((sum, item) => {
      return sum + (item.product_details.selling_price * item.quantity || 0);
    }, 0);

    return ` ${totalPriceSum}`;
  };

  return (
    <section className="md:col-span-1 py-7 px-7 rounded-md shadow-sm bg-white/[0.7] flex flex-col gap-6 w-full h-min">
      <Alert severity="info" variant="outlined">
          Shipping charges are free for orders above ₹5000
          {/* Shipping is free for all orders above ₹5000. */}
      </Alert>
      <h1 className="text-xl">Price Details</h1>
      {cart?.map((product) => (
        <PriceCard key={product.id} product={product} />
      ))}

      <hr />
      <div className="flex justify-between items-center">
        <p className=" text-gray-600">Total</p>
        <p className="text-2xl">₹ {calculateTotalPriceSum(cart)}</p>
      </div>

      <div className="w-full py-2   flex gap-4 items-center">
        <button
          className="btn-rounded-primary rounded-full flex items-center gap-2 md:text-sm lg:text-base"
          onClick={() => {
            setTimeout(() => {
              history.push("/checkout", {
                state: "cart",
              });
            }, 100);
          }}
        >
          Proceed to Checkout
        </button>
      </div>
    </section>
  );
};

export default CartTotalCard;
