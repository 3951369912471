import React, { useState, useEffect, useRef } from "react";
import { useSnackbar } from "notistack";
import axios from "axios";
import { MdKeyboardArrowUp } from "react-icons/md";
import SingleProduct from "../components/products/SingleProduct";
import PageWrapper from "../components/PageWrapper/PageWrapper";

const SearchPage = () => {
  const [showScrollArrow, setShowScrollArrow] = useState(false);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchType, setSearchType] = useState("productName");
  const { enqueueSnackbar } = useSnackbar();
  const auth = localStorage.getItem("authToken");

  const [inputValue, setInputValue] = useState("");
  const [selectedArtist, setSelectedArtist] = useState(0);
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const inputRef = useRef(null);

  const searchProducts = async (
    query = searchQuery,
    artistId = selectedArtist
  ) => {
    try {
      setLoading(true);
      setProducts([]);
      let res;
      switch (searchType) {
        case "productName":
          res = await axios.post(
            `${process.env.REACT_APP_BASEURL}/admin/product/search`,
            { word: query },
            {
              headers: { Authorization: `Bearer ${auth}` },
            }
          );
          break;
        case "artistName":
          res = await axios.post(
            `${process.env.REACT_APP_BASEURL}/admin/product/getbyartist`,
            { artist_id: artistId },
            {
              headers: { Authorization: `Bearer ${auth}` },
            }
          );
          break;
        case "trackName":
          res = await axios.post(
            `${process.env.REACT_APP_BASEURL}/admin/product/getbytrackname`,
            { word: query },
            {
              headers: { Authorization: `Bearer ${auth}` },
            }
          );
          break;
        default:
          break;
      }
      if (res.data.success) {
        setProducts(res.data.data);
      } else {
        enqueueSnackbar("No products found.", { variant: "info" });
        setProducts([]);
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Error occurred while searching products.", {
        variant: "error",
      });
    } finally {
      setLoading(false);
      setShowOptions(false); // Hide suggestions after search
      inputRef.current.focus(); // Keep focus on the input
    }
  };

  useEffect(() => {
    const fetchOptions = async () => {
      if (inputValue.length >= 3) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASEURL}/admin/product/searchartist`,
            {
              word: inputValue,
            },
            {
              headers: {
                Authorization: `Bearer ${auth}`,
              },
            }
          );

          if (response.data.success) {
            setOptions(response.data.data);
            setShowOptions(true);
          } else {
            enqueueSnackbar("Failed to fetch artists", { variant: "error" });
          }
        } catch (error) {
          console.error(error);
          enqueueSnackbar("Error occurred. Please try again.", {
            variant: "error",
          });
        }
      } else {
        setOptions([]);
        setShowOptions(false);
      }
    };

    fetchOptions();
  }, [inputValue, auth, enqueueSnackbar]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
    setSelectedIndex(-1);
    if (searchType !== "artistName") {
      setSearchQuery(e.target.value);
    }
  };

  const handleOptionClick = (option) => {
    setInputValue(option.name);
    setSelectedArtist(option.artist_id);
    setShowOptions(false);
    searchProducts(option.name, option.artist_id);
    inputRef.current.focus(); // Keep focus on the input
  };

  const handleKeyDown = (e) => {
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setSelectedIndex((prevIndex) =>
        prevIndex < options.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setSelectedIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (searchType === "artistName") {
        if (selectedIndex >= 0 && selectedIndex < options.length) {
          handleOptionClick(options[selectedIndex]);
        } else {
          // If no option is selected, search with the current input value
          searchProducts(inputValue, 0);
        }
      } else {
        searchProducts(searchQuery);
      }
      setShowOptions(false); // Hide suggestions after search
    }
  };

  return (
    <PageWrapper>
      <div className="mt-6">
        <div className="flex justify-center mb-6 space-x-4">
          <div className="flex items-center space-x-4">
            <input
              type="radio"
              id="product"
              name="searchType"
              value="productName"
              checked={searchType === "productName"}
              onChange={() => {
                setSearchType("productName");
                setInputValue("");
                setSearchQuery("");
              }}
              className="appearance-none h-3 w-3 border border-gray-300 rounded-md checked:bg-yellow-500 checked:border-transparent focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
            <label htmlFor="product" className="text-gray-700 cursor-pointer">
              Product
            </label>
          </div>
          <div className="flex items-center space-x-4">
            <input
              type="radio"
              id="artist"
              name="searchType"
              value="artistName"
              checked={searchType === "artistName"}
              onChange={() => {
                setSearchType("artistName");
                setInputValue("");
                setSearchQuery("");
              }}
              className="appearance-none h-3 w-3 border border-gray-300 rounded-md checked:bg-yellow-500 checked:border-transparent focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
            <label htmlFor="artist" className="text-gray-700 cursor-pointer">
              Artist
            </label>
          </div>
          <div className="flex items-center space-x-4">
            <input
              type="radio"
              id="track"
              name="searchType"
              value="trackName"
              checked={searchType === "trackName"}
              onChange={() => {
                setSearchType("trackName");
                setInputValue("");
                setSearchQuery("");
              }}
              className="appearance-none h-3 w-3 border border-gray-300 rounded-md checked:bg-yellow-500 checked:border-transparent focus:outline-none focus:ring-2 focus:ring-yellow-500"
            />
            <label htmlFor="track" className="text-gray-700 cursor-pointer">
              Track
            </label>
          </div>
        </div>

        <div className="flex justify-center mb-6 space-x-4">
          <div className="relative">
            <input
              ref={inputRef}
              id="search-input"
              type="text"
              placeholder={`Search by ${
                searchType === "productName"
                  ? "Product Name"
                  : searchType === "artistName"
                  ? "Artist Name"
                  : "Track Name"
              }...`}
              value={inputValue}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              className="border border-gray-400 rounded-md py-2 px-4 w-64 focus:outline-none"
              autoComplete="off"
            />
            {showOptions && searchType === "artistName" && (
              <div className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-b shadow-lg">
                {options.map((option, index) => (
                  <div
                    key={index}
                    className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                      index === selectedIndex ? "bg-gray-200" : ""
                    }`}
                    onClick={() => handleOptionClick(option)}
                  >
                    {option.name}
                  </div>
                ))}
              </div>
            )}
          </div>

          <button
            className="bg-yellow-600 text-white px-4 py-2 rounded-md"
            onClick={() => searchProducts()}
            disabled={loading}
          >
            {loading ? "Searching..." : "Search"}
          </button>
        </div>

        {products.length > 0 ? (
          <main className="relative grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
            {products.map((product) => (
              <SingleProduct
                key={product._id}
                product={{
                  _id: product.product_id,
                  qty: 0,
                  name: product.title,
                  description: product.description,
                  brand: product.category,
                  category: "Sports",
                  gender: "Men",
                  weight: "200g",
                  quantity: 10,
                  image: product.product_images[0],
                  rating: 4.5,
                  price: product.mrp,
                  newPrice: product.selling_price,
                  trending: true,
                  label: product.label,
                  type: product.product_type,
                }}
              />
            ))}
          </main>
        ) : (
          <p className="font-sans text-4xl font-bold uppercase tracking-wide text-gray-300 text-center w-full py-32">
            {loading ? "Searching..." : "Nothing to Show!"}
          </p>
        )}
      </div>
    </PageWrapper>
  );
};

export default SearchPage;
